import React , { useState,useEffect } from "react"

import { Link } from 'gatsby';

import Cookies from 'js-cookie';

import CookieConsent from 'react-cookie-consent';

import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'

import '../styles/style.scss';

import Header from './header';
import Footer from './footer';
import Drawer from './drawer';

const Layout = ({children, position, pageContext, pbUri},location) =>{

    // console.log(pageContext);
    let isDisp = true;
    
    if(children.props.pageContext.isDisp === false){
        isDisp = false;
    }

    const setCookies = () =>{
        let chkGdpr = Cookies.get('gdpr-as');

        chkGdpr && Cookies.set('cauth',true);

        initializeAndTrack(location);
    }

    const releaseCookies = () =>{
        window[`ga-disable-` + process.env.GA_ID] = true

        let chkGdpr = Cookies.get('gdpr-as');

        Object.keys(Cookies.get()).forEach(function(cookieName) {
            Cookies.remove(cookieName, { path:'/' });
        });

        Cookies.set('gdpr-as', chkGdpr);
    }



    const [isConsent, setConsent] = useState(false);

    useEffect(() => {
        setConsent(Cookies.get('gdpr-as'))

        if (isConsent === "false") {
            window[`ga-disable-` + process.env.GA_ID] = true
        }
    }, [isConsent]);



    return(
        <>
            {isDisp && <Header position={position} pbUri={pbUri} />}

                    {children}

            {isDisp && <Footer position={position} />}

            {isDisp && <Drawer position={position} />}

            <CookieConsent
                location="none"
                buttonText="同意する"
                declineButtonText="拒否する"
                cookieName="gdpr-as"
                expires={150}
                enableDeclineButton
                onAccept = {setCookies}
                onDecline = {releaseCookies}
                disableStyles={true}
                containerClasses="c-gdpr-box"
                buttonClasses="c-gdpr-buttons--button is-accept"
                buttonWrapperClasses="c-gdpr-buttons"
                declineButtonClasses="c-gdpr-buttons--button is-decline"
                contentClasses="c-gdpr-cnt"
            >
            当サイトでは利便性向上や閲覧の追跡のためにCookieを使用しております。<br />
            サイトの閲覧をつづけた場合Cookieの使用に同意したことになります。<Link to="/policy" title="外部送信ポリシー" className="c-text--link is-white is-small ml">外部送信ポリシー</Link>
            </CookieConsent>
        </>
    )    
}

export default Layout;


