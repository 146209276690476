import React from "react"
import Layout from "./src/components/layout";



import axios from 'axios'



export const wrapPageElement = ({ element, ...restProps }, ...args) => {
    return (
        <Layout
            name="wrapPageElement"
            props={restProps}
            args={args}
            mode="browser"
            position={element.key}
            pbUri={element.props.uri}
        >
            {element}

        </Layout>
    );
};



async function getAllPostData(limit){
    await axios.get(process.env.API_URL + `/news?limit=${limit}`, { 
        headers: {
            'Content-type': 'application/json',
            'X-API-KEY': process.env.API_KEY
        },
    }).then(result =>{
        // console.log(result)
        
        const post = result.data.contents

        sessionStorage.setItem('postData',JSON.stringify(post))
    }).catch(error =>{
        // console.log(error)
        sessionStorage.setItem('postData','is-error')
        console.clear() ;
    })
}

getAllPostData(1000)



export const onClientEntry = () =>{

}

export const onInitialClientRender = () => {

}

export const onPreRouteUpdate = ({ location, prevLocation}) => {

}

export const onRouteUpdate = ({ location }) => {

}

export const onPrefetchPathname = ({ pathname, loadPage }) =>{

}

export const onPostPrefetchPathname = ({ pathname }) =>{

}


