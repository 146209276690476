import React, { useState } from 'react';

import { Link } from 'gatsby';

const Drawer = (props) => {

    const [drawerStatus, setDrawerStatus] = useState(false)

    const drawer = (e) =>{
        e.preventDefault();

        if(drawerStatus === false){
            document.getElementsByClassName("js-drawer")[0].classList.add("is-close")
            document.getElementsByClassName("js-drawer-icon")[0].classList.add("is-close")

            document.body.classList.add('is-active')
            document.getElementsByTagName('html')[0].classList.add('is-active')
            document.getElementsByClassName("l-header-inner")[0].classList.add("is-active")
            document.getElementsByClassName("l-dialog")[0].classList.add("is-active")

            setDrawerStatus(true)
        }else{
            document.getElementsByClassName("js-drawer")[0].classList.remove("is-close")
            document.getElementsByClassName("js-drawer-icon")[0].classList.remove("is-close")

            document.body.classList.remove('is-active')
            document.getElementsByTagName('html')[0].classList.remove('is-active')
            document.getElementsByClassName("l-header-inner")[0].classList.remove("is-active")
            document.getElementsByClassName("l-dialog")[0].classList.remove("is-active")

            setDrawerStatus(false)
        }
    }

    const closeDrawer = () =>{
        document.getElementsByClassName("js-drawer")[0].classList.remove("is-close")
        document.getElementsByClassName("js-drawer-icon")[0].classList.remove("is-close")

        document.body.classList.remove('is-active')
        document.getElementsByTagName('html')[0].classList.remove('is-active')
        document.getElementsByClassName("l-header-inner")[0].classList.remove("is-active")
        document.getElementsByClassName("l-dialog")[0].classList.remove("is-active")

        setDrawerStatus(false)
    }

    return(
        <>

            <a href="#drawer" className="l-header-drawer js-drawer" onClick={drawer}>
                <i className="l-header-drawer__btn js-drawer-icon"></i>
            </a>

            <div className="l-dialog">
                <div className="l-dialog-inner">

                    <div className="l-dialog-box">
                        <div className="l-dialog-nav">
                            <ul className="l-dialog-nav__list">

                                <li className="l-dialog-nav__item">
                                    <Link to="/solution" title="CONTACT" className="l-dialog-nav__item__btn u-eng" onClick={closeDrawer}>
                                        SOLUTION
                                    </Link>
                                </li>

                                <li className="l-dialog-nav__item">
                                    <Link to="/mission" title="CONTACT" className="l-dialog-nav__item__btn u-eng" onClick={closeDrawer}>
                                        MISSION                                     
                                    </Link>
                                </li>

                                <li className="l-dialog-nav__item">
                                    <Link to="/vision" title="CONTACT" className="l-dialog-nav__item__btn u-eng" onClick={closeDrawer}>
                                        COMPANY                                 
                                    </Link>
                                </li>

                                <li className="l-dialog-nav__item">
                                    <Link to="/recruit" title="CONTACT" className="l-dialog-nav__item__btn u-eng" onClick={closeDrawer}>
                                        RECRUIT
                                    </Link>
                                </li>

                            </ul>
                        </div>
                    </div>

                </div>
            </div>

        </>
    )
}

export default Drawer;


